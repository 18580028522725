import { Box, ImageList, ImageListItem, ImageListItemBar, Skeleton, useTheme } from '@mui/material';
import { HOLOPLAYER_PATH } from '../../../route/route';
import NavigationBar from '../../ui/NavigationBar/NavigationBar';
import AlbumImage from '../../ui/AlbumImage';
import { Link } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import NoContent from '../../ui/NoContent';
import useAlbum from '../../../slice/album/useAlbum';
import { hiraganaToKatakana, katakanaToHiragana } from '../../../lib/misc';
import { DEFAULT_LOADING_SKELETON_COUNT } from '../../../constants';

const AlbumCollectionPage = () => {
    const Album = useAlbum();
    const [query, setQuery] = useState<string>('');
    const theme = useTheme();
    const [cols, setCols] = useState<number>(2);

    useEffect(() => {
        Album.fetchAlbum();
    }, [Album]);

    useEffect(() => {
        Album.setSearchString();
    }, [Album]);

    const albums = useMemo(() => {
        let albumItems = Album.items;
        if (!query) return albumItems;
        const hiragana = katakanaToHiragana(query);
        const katakana = hiraganaToKatakana(query);

        return albumItems.filter((album) => {
            const search_array = [album.title, album.search_all];
            return new RegExp(`(${hiragana}|${katakana})`, 'igms').test(search_array.join(''));
        });
    }, [query, Album.items]);

    const getColumns = useCallback(
        (width: number) => {
            if (width < theme.breakpoints.values.sm) {
                return 2;
            } else if (width < theme.breakpoints.values.md) {
                return 3;
            } else if (width < theme.breakpoints.values.lg) {
                return 4;
            } else if (width < theme.breakpoints.values.xl) {
                return 6;
            } else {
                return 8;
            }
        },
        [theme.breakpoints.values],
    );

    const updateDimensions = useCallback(() => {
        setCols(getColumns(window.innerWidth));
    }, [getColumns]);

    useEffect(() => {
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, [updateDimensions]);

    const Content = (): JSX.Element => {
        if (Album.isLoaded) {
            if (albums.length > 0) {
                return (
                    <ImageList gap={16} sx={{ margin: '16px' }} cols={cols}>
                        {albums.map((item, index) => (
                            <ImageListItem
                                key={index}
                                sx={{
                                    '.MuiImageListItem-img': {
                                        borderRadius: '8px',
                                        border: 'solid 0.5px rgba(255,255,255,0.1)',
                                    },
                                    color: theme.palette.text.primary,
                                    textDecoration: 'unset',
                                    overflow: 'hidden',
                                }}
                                component={Link}
                                to={HOLOPLAYER_PATH.LIBRARY_ALBUM_COLLECTION + `/${item.id}`}
                            >
                                <AlbumImage
                                    album={item}
                                    alt={item.title}
                                    sx={{ width: '100%' }}
                                />
                                <ImageListItemBar
                                    title={item.title}
                                    // subtitle={<span>by: {item.author}</span>}
                                    position="below"
                                    sx={{ '.MuiImageListItemBar-title': { fontSize: '14px' } }}
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                );
            } else {
                return <NoContent />;
            }
        }

        return (
            <ImageList gap={16} sx={{ margin: '16px' }} cols={cols}>
                {Array(DEFAULT_LOADING_SKELETON_COUNT)
                    .fill(null)
                    .map((item, index) => {
                        return (
                            <ImageListItem key={index}>
                                <Skeleton
                                    sx={{
                                        aspectRatio: '1',
                                        transform: 'inherit',
                                    }}
                                />
                                <Skeleton
                                    variant="text"
                                    sx={{
                                        fontSize: '16px',
                                        margin: '2px 0',
                                        padding: '4px 0',
                                        width: '50%',
                                    }}
                                />
                            </ImageListItem>
                        );
                    })}
            </ImageList>
        );
    };

    return (
        <Box>
            <NavigationBar
                title="アルバム"
                to={HOLOPLAYER_PATH.LIBRARY}
                query={query}
                setQuery={setQuery}
                placeholder="アルバム名、収録曲名、タレント..."
            />
            <Content />
        </Box>
    );
};

export default AlbumCollectionPage;
