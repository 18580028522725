import {
    Avatar,
    Box,
    Divider,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Skeleton,
    useTheme,
} from '@mui/material';
import { HOLOPLAYER_PATH } from '../../../route/route';
import { TalentItemType } from '../../../type/hololive.types';
import { Link } from 'react-router-dom';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';

const TalentCollectionListItem = ({ talent }: { talent: TalentItemType | 'loading' }) => {
    const theme = useTheme();
    if (talent === 'loading') {
        return (
            <Box>
                <ListItem
                    sx={[
                        {
                            padding: 0,
                            color: theme.palette.text.primary,
                        },
                        {
                            '.MuiListItemButton-root': { padding: '8px 16px' },
                        },
                    ]}
                    dense
                >
                    <ListItemButton>
                        <ListItemIcon sx={{ minWidth: '44px' }}>
                            <Skeleton
                                sx={{
                                    width: '40px',
                                    height: '40px',
                                    marginRight: '16px',
                                }}
                                variant="circular"
                            />
                        </ListItemIcon>
                        <Box>
                            <Skeleton width="120px" variant="text" sx={{ fontSize: '18px' }} />
                        </Box>
                        <ListItemText
                            secondary={''}
                            sx={{
                                alignItems: 'end',
                                textAlign: 'end',
                                marginRight: 1,
                            }}
                        />
                        <NavigateNextRoundedIcon color="disabled" />
                    </ListItemButton>
                </ListItem>
                <Divider variant="inset" component="li" sx={{ marginLeft: '70px' }} />
            </Box>
        );
    }
    const count = talent?.count && talent.count > 0 ? `${talent.count}` : '';
    const primary = `${talent.jp_formal}`;
    const secondary = '';
    const tertiary = `${count}`;
    return (
        <Box>
            <ListItem
                to={HOLOPLAYER_PATH.LIBRARY_TALENT_COLLECTION + `/${talent.id}`}
                button={true}
                component={Link}
                sx={[
                    {
                        padding: 0,
                        color: theme.palette.text.primary,
                    },
                    {
                        '.MuiListItemButton-root': { padding: '8px 16px' },
                    },
                ]}
                dense
            >
                <ListItemButton>
                    <ListItemIcon sx={{ minWidth: '44px' }}>
                        <Avatar
                            sx={{
                                width: '40px',
                                height: '40px',
                                marginRight: '16px',
                            }}
                            src={`/img/talent/${talent.en_kebab}.png`}
                        />
                    </ListItemIcon>
                    <Box>
                        <ListItemText primary={primary} secondary={secondary} />
                    </Box>
                    <ListItemText
                        secondary={tertiary}
                        sx={{
                            alignItems: 'end',
                            textAlign: 'end',
                            marginRight: 1,
                        }}
                    />
                    <NavigateNextRoundedIcon color="disabled" />
                </ListItemButton>
            </ListItem>
            <Divider variant="inset" component="li" sx={{ marginLeft: '70px' }} />
        </Box>
    );
};

export default TalentCollectionListItem;
